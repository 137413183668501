.holder {
    margin-top: 6rem;
    margin-bottom: 8rem;
}
.navbar .navbar-nav .nav-item .nav-link.active:not(.btn),
.navbar .navbar-nav .nav-item .nav-link:not(.btn):active,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):focus,
.navbar .navbar-nav .nav-item .nav-link:not(.btn):hover {
    background-color: hsla(0, 0%, 100%, .2);
    border-radius: .1875rem;
}
h1,
h2,
h3 {
    word-break: break-word;
}