@import "~bootstrap/scss/bootstrap";


/*
====================================================================
***************************  List Group  ***************************
====================================================================
 */
.card-footer .list-group-item {
  background-color: #fff;
}