/*!

 =========================================================
 * Now UI Kit - v1.3.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

@import 'variables';
@import 'mixins';

// Plugins CSS
@import "plugins/plugin-bootstrap-switch";
@import "plugins/plugin-nouislider";
@import "plugins/plugin-datepicker";

// Core CSS
@import "buttons";
@import "inputs";
@import "checkboxes-radio";
@import "progress";
@import "badges";
@import "pagination";
@import "typography";
@import "misc";
@import "pills";

// components
@import "nucleo-outline";

@import "tabs";
@import "navbar";
@import "dropdown";
@import "alerts";
@import "images";
@import "popups";
@import "modals";
@import "carousel";
@import "footers";

// Cards
@import "cards";
@import "cards/card-plain";
@import "cards/card-signup";

// example pages and sections
@import "example-pages";
@import "sections";


@import "responsive";
